body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice,
.roomInit .title,
.roomInit button {
  font-family: 'shojumaru';
}
.roomInit {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.roomInit .title {
  color: #795548;
  font-size: 12vmin;
}
.roomInit .roomForm {
  background-color: white;
  padding: 4vmax;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 4vmax;
}
.roomInit .buttons-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 3vmin;
}
.roomInit .buttons-wrapper > button:first-child {
  margin-right: 1vmin;
}
.roomInit .buttons-wrapper button {
  padding: 1.5vmax;
}
.roomInit .logo {
  width: 15vmax;
  height: 15vmax;
}
.roomInit .version {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.roomInit .version .big {
  font-size: 30px;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice {
  font-family: 'shojumaru';
}
.infosPlayerWrapper {
  display: flex;
  align-items: center;
}
.infosPlayerWrapper .iconeLocal > svg {
  height: 6vmax;
  width: 6vmax;
}
.infosPlayerWrapper .infosButton {
  color: orange;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice {
  font-family: 'shojumaru';
}
.logsWrapper {
  display: flex;
  flex-direction: column;
  flex: 40 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.logsWrapper .logs {
  padding: 1vmin;
  text-align: center;
}
.logsWrapper .logs .voteLock {
  color: #dc0f55;
  font-weight: 700;
}
.logsWrapper .logs .colorized {
  text-transform: uppercase;
  font-weight: bold;
}
.logsWrapper .logs .divider {
  border-color: #dc0f55;
  width: 40%;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice {
  font-family: 'shojumaru';
}
.player {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14vmax;
}
.player.isVoting {
  cursor: pointer;
}
.player .votes {
  height: 4vmax;
  display: flex;
  width: 100%;
  justify-content: center;
}
.player .votes .voteIcon {
  width: 3vmax;
  height: 3vmax;
  margin-top: 0.8vmax;
}
.player.selected .iconWrapper {
  border-bottom: 4px solid #dc0f55;
  margin-bottom: -4px;
}
.player .playerName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 95%;
  text-align: center;
  margin-bottom: 5px;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice,
.main .topBar .roomID,
.main .currentState,
.titleModal {
  font-family: 'shojumaru';
}
.main {
  color: white;
  flex-direction: column;
}
.main .topBar {
  display: flex;
  flex: 10 1;
  justify-content: space-between;
  width: 100%;
}
.main .topBar .roomID {
  color: #795548;
  font-size: 2vmax;
  padding: 2vmax;
}
.main .currentState {
  display: flex;
  flex: 10 1;
  align-items: center;
  font-size: 3vmax;
}
.main .mainContent {
  display: flex;
  flex: 72 1;
  overflow-y: auto;
}
.main .mainContent .playerList {
  display: flex;
  flex: 60 1;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  align-self: flex-end;
}
.main .actionBar {
  display: flex;
  flex: 8 1;
  margin: 2vmax;
}
.main .custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  /* NOSONAR: Valide à partir de FF64 */
  scrollbar-color: #795548 transparent;
  /* NOSONAR: Valide à partir de FF64 */
  /* Chrome */
}
.main .custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.main .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #795548;
}
/* La modal est en dehors de main malheureusement ...*/
.titleModal {
  text-align: center;
  font-size: 3vmax;
}
.playerListModal {
  display: flex;
  justify-content: center;
}
.playerListModal .player {
  width: unset;
}
.roleIcon {
  height: 7vmax;
  width: 7vmax;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice,
.lobby,
.lobby .startGame {
  font-family: 'shojumaru';
}
.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.lobby .motd {
  text-align: center;
  padding: 2vmax;
  font-size: 2vmax;
}
.lobby .motd .idRoom {
  color: #dc0f55;
  font-size: 3vmax;
}
.lobby .playerNameList {
  font-size: 1.7vmax;
  padding: 2vmax;
}
.lobby .startGame {
  font-size: 2vmax;
}

/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin-ext.06eb3510.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(/static/media/shojumaru-latin.4189758d.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice {
  font-family: 'shojumaru';
}

