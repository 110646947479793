/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(../../Fonts/shojumaru-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(../../Fonts/shojumaru-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice {
  font-family: 'shojumaru';
}
.logsWrapper {
  display: flex;
  flex-direction: column;
  flex: 40 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.logsWrapper .logs {
  padding: 1vmin;
  text-align: center;
}
.logsWrapper .logs .voteLock {
  color: #dc0f55;
  font-weight: 700;
}
.logsWrapper .logs .colorized {
  text-transform: uppercase;
  font-weight: bold;
}
.logsWrapper .logs .divider {
  border-color: #dc0f55;
  width: 40%;
}
