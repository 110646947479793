/* latin-ext */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(../Fonts/shojumaru-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Shojumaru'), local('Shojumaru-Regular'), url(../Fonts/shojumaru-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.fullScreen {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.myPolice,
.main .topBar .roomID,
.main .currentState,
.titleModal {
  font-family: 'shojumaru';
}
.main {
  color: white;
  flex-direction: column;
}
.main .topBar {
  display: flex;
  flex: 10 1;
  justify-content: space-between;
  width: 100%;
}
.main .topBar .roomID {
  color: #795548;
  font-size: 2vmax;
  padding: 2vmax;
}
.main .currentState {
  display: flex;
  flex: 10 1;
  align-items: center;
  font-size: 3vmax;
}
.main .mainContent {
  display: flex;
  flex: 72 1;
  overflow-y: auto;
}
.main .mainContent .playerList {
  display: flex;
  flex: 60 1;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  align-self: flex-end;
}
.main .actionBar {
  display: flex;
  flex: 8 1;
  margin: 2vmax;
}
.main .custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  /* NOSONAR: Valide à partir de FF64 */
  scrollbar-color: #795548 transparent;
  /* NOSONAR: Valide à partir de FF64 */
  /* Chrome */
}
.main .custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.main .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #795548;
}
/* La modal est en dehors de main malheureusement ...*/
.titleModal {
  text-align: center;
  font-size: 3vmax;
}
.playerListModal {
  display: flex;
  justify-content: center;
}
.playerListModal .player {
  width: unset;
}
.roleIcon {
  height: 7vmax;
  width: 7vmax;
}
